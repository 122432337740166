import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../utils";

// notify Segment analytics with page api call on route/path change
export function SegmentPager() {
  const location = useLocation();
  const { currentUser } = useAuthContext();
  const { customer } = useCustomerContext({
    allowNull: true,
  });
  const [prevLocation, setPrevLocation] = useState("");

  useEffect(() => {
    try {
      if (prevLocation !== location.pathname) {
        setPrevLocation(location.pathname);
        globalThis.analytics.page({
          authUserId: currentUser?.uid || "",
          email: currentUser?.email,
          customerId: customer?.id,
          "Customer ID": customer?.id || "", // this is for backward compatibility in Mixpanel
          customerName: customer?._name,
        });
      }
    } catch (e) {
      consoleErrorWithSentry(e);
    }
  }, [location.pathname, prevLocation, currentUser?.uid, currentUser?.email, customer?.id, customer?._name]);

  return null;
}
